import { useQuery } from 'react-query';
import dayjs from 'dayjs';
import { BigNumber } from 'ethers';

import { useWeb3Context } from 'src/context/web3';
import { notify } from 'src/utils/notify';
import web3config from 'src/utils/web3config';

import useWeb3Helper from './useWeb3Helper';

const queryGetSubscriptionPrograms = 'queryGetSubscriptionPrograms';

const useWeb3Subscription = () => {
	const { getContract, address } = useWeb3Context();
	const { subscriptionAddress, subscriptionAbi } = web3config;
	const { web3Approval } = useWeb3Helper();

	const web3GetSubscriptionPlan = async () => {
		if (getContract) {
			try {
				const contract = await getContract(subscriptionAbi, subscriptionAddress);
				const subscriptionPrograms = await contract.methods.getPrograms().call();
				return subscriptionPrograms;
			} catch (err: any) {
				console.log(err);
				notify(err?.message, 'error');
				return false;
			}
		}
		return false;
	};

	const web3BuySubscription = async (
		chosenDay: string,
		priceInCreo: string | number | BigNumber,
	) => {
		if (getContract) {
			try {
				await web3Approval(subscriptionAddress, priceInCreo);
				const subscriptionContract = await getContract(subscriptionAbi, subscriptionAddress);
				const result = await subscriptionContract.methods
					.buySubscription(chosenDay)
					.send({ from: address });

				notify('Subscription purchase successful!');
				return result;
			} catch (err: any) {
				notify(err?.message, 'error');
				return false;
			}
		}
		return false;
	};

	const timeout = (ms: number) => {
		const message =
			'Subscription operation timed out. Check your internet and try again. VPN may help.';

		return new Promise((_, reject) => setTimeout(() => reject(new Error(message)), ms));
	};

	const web3CheckSubscription = async () => {
		try {
			if (getContract && address) {
				const contract = await getContract(subscriptionAbi, subscriptionAddress);

				const result = await Promise.race([
					contract.methods.subscriptions(address).call(),
					timeout(6000),
				]);

				if (!result) {
					return false;
				}

				const end = dayjs.unix(result.endDate);
				const now = dayjs();
				const diff = end.diff(now);

				return {
					endDate: result.endDate,
					isActive: result.isActive && !isNaN(diff) && diff > 0,
				};
			}
			return false;
		} catch (err: any) {
			console.log(err);
			notify(err?.message, 'error');
			return false;
		}
	};

	return { web3BuySubscription, web3GetSubscriptionPlan, web3CheckSubscription };
};

export const useGetSubscriptionPrograms = () => {
	const { getContract } = useWeb3Context();

	const { web3GetSubscriptionPlan } = useWeb3Subscription();
	return useQuery(
		[queryGetSubscriptionPrograms],
		() => {
			return web3GetSubscriptionPlan();
		},
		{ keepPreviousData: true, refetchOnWindowFocus: false, enabled: !!getContract },
	);
};

export default useWeb3Subscription;

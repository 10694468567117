const stakingabi = [
	{ inputs: [], stateMutability: 'nonpayable', type: 'constructor' },
	{
		anonymous: false,
		inputs: [
			{ indexed: true, internalType: 'address', name: 'owner', type: 'address' },
			{ indexed: true, internalType: 'address', name: 'spender', type: 'address' },
			{ indexed: false, internalType: 'uint256', name: 'value', type: 'uint256' },
		],
		name: 'Approval',
		type: 'event',
	},
	{
		inputs: [
			{ internalType: 'address', name: 'spender', type: 'address' },
			{ internalType: 'uint256', name: 'amount', type: 'uint256' },
		],
		name: 'approve',
		outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
		stateMutability: 'nonpayable',
		type: 'function',
	},
	{
		inputs: [
			{ internalType: 'address', name: 'spender', type: 'address' },
			{ internalType: 'uint256', name: 'subtractedValue', type: 'uint256' },
		],
		name: 'decreaseAllowance',
		outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
		stateMutability: 'nonpayable',
		type: 'function',
	},
	{
		inputs: [
			{ internalType: 'address', name: 'spender', type: 'address' },
			{ internalType: 'uint256', name: 'addedValue', type: 'uint256' },
		],
		name: 'increaseAllowance',
		outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
		stateMutability: 'nonpayable',
		type: 'function',
	},
	{
		inputs: [
			{ internalType: 'uint256', name: '_programIndex', type: 'uint256' },
			{ internalType: 'uint256', name: '_amount', type: 'uint256' },
		],
		name: 'joinstake',
		outputs: [],
		stateMutability: 'nonpayable',
		type: 'function',
	},
	{
		inputs: [
			{ internalType: 'uint256', name: '_programIndex', type: 'uint256' },
			{ internalType: 'uint256', name: '_stakingIndex', type: 'uint256' },
		],
		name: 'leaveStake',
		outputs: [],
		stateMutability: 'nonpayable',
		type: 'function',
	},
	{
		anonymous: false,
		inputs: [
			{ indexed: true, internalType: 'address', name: 'previousOwner', type: 'address' },
			{ indexed: true, internalType: 'address', name: 'newOwner', type: 'address' },
		],
		name: 'OwnershipTransferred',
		type: 'event',
	},
	{
		inputs: [{ internalType: 'uint256', name: '_programIndex', type: 'uint256' }],
		name: 'reclaimDeposit',
		outputs: [],
		stateMutability: 'nonpayable',
		type: 'function',
	},
	{
		inputs: [],
		name: 'renounceOwnership',
		outputs: [],
		stateMutability: 'nonpayable',
		type: 'function',
	},
	{
		inputs: [
			{ internalType: 'address', name: '_addr', type: 'address' },
			{ internalType: 'bool', name: '_active', type: 'bool' },
		],
		name: 'setGovernance',
		outputs: [],
		stateMutability: 'nonpayable',
		type: 'function',
	},
	{
		inputs: [
			{ internalType: 'uint256', name: '_programIndex', type: 'uint256' },
			{ internalType: 'uint256', name: '_newapy', type: 'uint256' },
		],
		name: 'setProgramApy',
		outputs: [],
		stateMutability: 'nonpayable',
		type: 'function',
	},
	{
		inputs: [
			{ internalType: 'uint256', name: '_programIndex', type: 'uint256' },
			{ internalType: 'uint256', name: '_newpenalty', type: 'uint256' },
		],
		name: 'setProgramPenalty',
		outputs: [],
		stateMutability: 'nonpayable',
		type: 'function',
	},
	{
		inputs: [
			{ internalType: 'uint256', name: '_programIndex', type: 'uint256' },
			{ internalType: 'address', name: '_addr', type: 'address' },
		],
		name: 'setProgramPenaltyWallet',
		outputs: [],
		stateMutability: 'nonpayable',
		type: 'function',
	},
	{
		inputs: [
			{ internalType: 'address', name: '_stakeTokenAddress', type: 'address' },
			{ internalType: 'address', name: '_rewardTokenAddress', type: 'address' },
			{ internalType: 'string', name: '_stakeTitle', type: 'string' },
			{ internalType: 'uint256', name: '_deposit', type: 'uint256' },
			{ internalType: 'uint256', name: '_period', type: 'uint256' },
			{ internalType: 'uint256', name: '_apy', type: 'uint256' },
			{ internalType: 'uint256', name: '_penalty', type: 'uint256' },
			{ internalType: 'address', name: '_penaltyWallet', type: 'address' },
		],
		name: 'setStakeProgram',
		outputs: [],
		stateMutability: 'nonpayable',
		type: 'function',
	},
	{
		inputs: [
			{ internalType: 'uint256', name: '_programIndex', type: 'uint256' },
			{ internalType: 'uint256', name: '_active', type: 'uint256' },
		],
		name: 'setStakeProgramStatus',
		outputs: [],
		stateMutability: 'nonpayable',
		type: 'function',
	},
	{
		inputs: [
			{ internalType: 'uint256', name: '_programIndex', type: 'uint256' },
			{ internalType: 'uint256', name: '_topupAmount', type: 'uint256' },
		],
		name: 'topupDeposit',
		outputs: [],
		stateMutability: 'nonpayable',
		type: 'function',
	},
	{
		inputs: [
			{ internalType: 'address', name: 'to', type: 'address' },
			{ internalType: 'uint256', name: 'amount', type: 'uint256' },
		],
		name: 'transfer',
		outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
		stateMutability: 'nonpayable',
		type: 'function',
	},
	{
		anonymous: false,
		inputs: [
			{ indexed: true, internalType: 'address', name: 'from', type: 'address' },
			{ indexed: true, internalType: 'address', name: 'to', type: 'address' },
			{ indexed: false, internalType: 'uint256', name: 'value', type: 'uint256' },
		],
		name: 'Transfer',
		type: 'event',
	},
	{
		inputs: [
			{ internalType: 'address', name: 'from', type: 'address' },
			{ internalType: 'address', name: 'to', type: 'address' },
			{ internalType: 'uint256', name: 'amount', type: 'uint256' },
		],
		name: 'transferFrom',
		outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
		stateMutability: 'nonpayable',
		type: 'function',
	},
	{
		inputs: [{ internalType: 'address', name: 'newOwner', type: 'address' }],
		name: 'transferOwnership',
		outputs: [],
		stateMutability: 'nonpayable',
		type: 'function',
	},
	{
		inputs: [
			{ internalType: 'address', name: 'addr', type: 'address' },
			{ internalType: 'uint256', name: '_programIndex', type: 'uint256' },
			{ internalType: 'uint256', name: '_stakingIndex', type: 'uint256' },
		],
		name: 'wdInterest',
		outputs: [],
		stateMutability: 'nonpayable',
		type: 'function',
	},
	{
		inputs: [
			{ internalType: 'address', name: 'owner', type: 'address' },
			{ internalType: 'address', name: 'spender', type: 'address' },
		],
		name: 'allowance',
		outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
		stateMutability: 'view',
		type: 'function',
	},
	{
		inputs: [{ internalType: 'address', name: 'account', type: 'address' }],
		name: 'balanceOf',
		outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
		stateMutability: 'view',
		type: 'function',
	},
	{
		inputs: [
			{ internalType: 'address', name: 'addr', type: 'address' },
			{ internalType: 'uint256', name: '_programIndex', type: 'uint256' },
			{ internalType: 'uint256', name: '_stakingIndex', type: 'uint256' },
		],
		name: 'calcInterest',
		outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
		stateMutability: 'view',
		type: 'function',
	},
	{
		inputs: [
			{ internalType: 'address', name: 'addr', type: 'address' },
			{ internalType: 'uint256', name: '_programIndex', type: 'uint256' },
			{ internalType: 'uint256', name: '_stakingIndex', type: 'uint256' },
		],
		name: 'calcInterestPerSecond',
		outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
		stateMutability: 'view',
		type: 'function',
	},
	{
		inputs: [
			{ internalType: 'address', name: 'addr', type: 'address' },
			{ internalType: 'uint256', name: '_programIndex', type: 'uint256' },
			{ internalType: 'uint256', name: '_stakingIndex', type: 'uint256' },
		],
		name: 'calcPenalty',
		outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
		stateMutability: 'view',
		type: 'function',
	},
	{
		inputs: [
			{ internalType: 'address', name: 'addr', type: 'address' },
			{ internalType: 'uint256', name: '_programIndex', type: 'uint256' },
			{ internalType: 'uint256', name: '_stakingIndex', type: 'uint256' },
		],
		name: 'calcStakingTime',
		outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
		stateMutability: 'view',
		type: 'function',
	},
	{
		inputs: [],
		name: 'decimals',
		outputs: [{ internalType: 'uint8', name: '', type: 'uint8' }],
		stateMutability: 'view',
		type: 'function',
	},
	{
		inputs: [
			{ internalType: 'address', name: 'addr', type: 'address' },
			{ internalType: 'uint256', name: '_programindex', type: 'uint256' },
		],
		name: 'getParticipantStakingCount',
		outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
		stateMutability: 'view',
		type: 'function',
	},
	{
		inputs: [
			{ internalType: 'address', name: 'addr', type: 'address' },
			{ internalType: 'uint256', name: '_programIndex', type: 'uint256' },
			{ internalType: 'uint256', name: '_stakingIndex', type: 'uint256' },
		],
		name: 'getParticipantStakingInfo1',
		outputs: [
			{ internalType: 'uint256', name: '', type: 'uint256' },
			{ internalType: 'uint256', name: '', type: 'uint256' },
			{ internalType: 'uint256', name: '', type: 'uint256' },
			{ internalType: 'uint256', name: '', type: 'uint256' },
		],
		stateMutability: 'view',
		type: 'function',
	},
	{
		inputs: [
			{ internalType: 'address', name: 'addr', type: 'address' },
			{ internalType: 'uint256', name: '_programIndex', type: 'uint256' },
			{ internalType: 'uint256', name: '_stakingIndex', type: 'uint256' },
		],
		name: 'getParticipantStakingInfo2',
		outputs: [
			{ internalType: 'uint256', name: '', type: 'uint256' },
			{ internalType: 'uint256', name: '', type: 'uint256' },
			{ internalType: 'uint256', name: '', type: 'uint256' },
			{ internalType: 'uint256', name: '', type: 'uint256' },
		],
		stateMutability: 'view',
		type: 'function',
	},
	{
		inputs: [{ internalType: 'uint256', name: '_programIndex', type: 'uint256' }],
		name: 'getStakedInfo',
		outputs: [
			{ internalType: 'uint256', name: '', type: 'uint256' },
			{ internalType: 'uint256', name: '', type: 'uint256' },
		],
		stateMutability: 'view',
		type: 'function',
	},
	{
		inputs: [{ internalType: 'uint256', name: '_programIndex', type: 'uint256' }],
		name: 'getStakeProgram1',
		outputs: [
			{ internalType: 'address', name: '', type: 'address' },
			{ internalType: 'address', name: '', type: 'address' },
			{ internalType: 'string', name: '', type: 'string' },
			{ internalType: 'address', name: '', type: 'address' },
			{ internalType: 'address', name: '', type: 'address' },
			{ internalType: 'uint256', name: '', type: 'uint256' },
		],
		stateMutability: 'view',
		type: 'function',
	},
	{
		inputs: [{ internalType: 'uint256', name: '_programIndex', type: 'uint256' }],
		name: 'getStakeProgram2',
		outputs: [
			{ internalType: 'uint256', name: '', type: 'uint256' },
			{ internalType: 'uint256', name: '', type: 'uint256' },
			{ internalType: 'uint256', name: '', type: 'uint256' },
			{ internalType: 'uint256', name: '', type: 'uint256' },
			{ internalType: 'uint256', name: '', type: 'uint256' },
		],
		stateMutability: 'view',
		type: 'function',
	},
	{
		inputs: [],
		name: 'getStakeProgramsLength',
		outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
		stateMutability: 'view',
		type: 'function',
	},
	{
		inputs: [{ internalType: 'address', name: '_addr', type: 'address' }],
		name: 'isAdmin',
		outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
		stateMutability: 'view',
		type: 'function',
	},
	{
		inputs: [{ internalType: 'address', name: '', type: 'address' }],
		name: 'isGovernance',
		outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
		stateMutability: 'view',
		type: 'function',
	},
	{
		inputs: [],
		name: 'name',
		outputs: [{ internalType: 'string', name: '', type: 'string' }],
		stateMutability: 'view',
		type: 'function',
	},
	{
		inputs: [],
		name: 'owner',
		outputs: [{ internalType: 'address', name: '', type: 'address' }],
		stateMutability: 'view',
		type: 'function',
	},
	{
		inputs: [
			{ internalType: 'address', name: '', type: 'address' },
			{ internalType: 'uint256', name: '', type: 'uint256' },
			{ internalType: 'uint256', name: '', type: 'uint256' },
		],
		name: 'ParticipantInStaking',
		outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
		stateMutability: 'view',
		type: 'function',
	},
	{
		inputs: [
			{ internalType: 'address', name: '', type: 'address' },
			{ internalType: 'uint256', name: '', type: 'uint256' },
		],
		name: 'ParticipantStakingCount',
		outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
		stateMutability: 'view',
		type: 'function',
	},
	{
		inputs: [
			{ internalType: 'address', name: '', type: 'address' },
			{ internalType: 'uint256', name: '', type: 'uint256' },
			{ internalType: 'uint256', name: '', type: 'uint256' },
		],
		name: 'ParticipantStakingDetail',
		outputs: [
			{ internalType: 'address', name: 'participant', type: 'address' },
			{ internalType: 'uint256', name: 'programIndex', type: 'uint256' },
			{ internalType: 'uint256', name: 'stakeAmount', type: 'uint256' },
			{ internalType: 'uint256', name: 'start', type: 'uint256' },
			{ internalType: 'uint256', name: 'apy', type: 'uint256' },
			{ internalType: 'uint256', name: 'penalty', type: 'uint256' },
			{ internalType: 'uint256', name: 'period', type: 'uint256' },
			{ internalType: 'uint256', name: 'withdrawn', type: 'uint256' },
			{ internalType: 'uint256', name: 'status', type: 'uint256' },
		],
		stateMutability: 'view',
		type: 'function',
	},
	{
		inputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
		name: 'StakedAmount',
		outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
		stateMutability: 'view',
		type: 'function',
	},
	{
		inputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
		name: 'StakedCount',
		outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
		stateMutability: 'view',
		type: 'function',
	},
	{
		inputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
		name: 'StakeProgramDetail',
		outputs: [
			{ internalType: 'address', name: 'stakeTokenAddress', type: 'address' },
			{ internalType: 'address', name: 'rewardTokenAddress', type: 'address' },
			{ internalType: 'string', name: 'stakeTitle', type: 'string' },
			{ internalType: 'uint256', name: 'deposit', type: 'uint256' },
			{ internalType: 'uint256', name: 'withdrawn', type: 'uint256' },
			{ internalType: 'uint256', name: 'period', type: 'uint256' },
			{ internalType: 'uint256', name: 'apy', type: 'uint256' },
			{ internalType: 'uint256', name: 'penalty', type: 'uint256' },
			{ internalType: 'address', name: 'creator', type: 'address' },
			{ internalType: 'address', name: 'penaltyWallet', type: 'address' },
			{ internalType: 'uint256', name: 'status', type: 'uint256' },
		],
		stateMutability: 'view',
		type: 'function',
	},
	{
		inputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
		name: 'StakePrograms',
		outputs: [
			{ internalType: 'address', name: 'stakeTokenAddress', type: 'address' },
			{ internalType: 'address', name: 'rewardTokenAddress', type: 'address' },
			{ internalType: 'string', name: 'stakeTitle', type: 'string' },
			{ internalType: 'uint256', name: 'deposit', type: 'uint256' },
			{ internalType: 'uint256', name: 'withdrawn', type: 'uint256' },
			{ internalType: 'uint256', name: 'period', type: 'uint256' },
			{ internalType: 'uint256', name: 'apy', type: 'uint256' },
			{ internalType: 'uint256', name: 'penalty', type: 'uint256' },
			{ internalType: 'address', name: 'creator', type: 'address' },
			{ internalType: 'address', name: 'penaltyWallet', type: 'address' },
			{ internalType: 'uint256', name: 'status', type: 'uint256' },
		],
		stateMutability: 'view',
		type: 'function',
	},
	{
		inputs: [],
		name: 'symbol',
		outputs: [{ internalType: 'string', name: '', type: 'string' }],
		stateMutability: 'view',
		type: 'function',
	},
	{
		inputs: [],
		name: 'totalSupply',
		outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
		stateMutability: 'view',
		type: 'function',
	},
];

export default stakingabi;

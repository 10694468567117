import { setAuthUserData } from 'src/redux/slices/authSlice';
import { selectAuth, useAppDispatch, useAppSelector } from 'src/redux/store';

interface IUser {
	creo_id: string;
	wallet_id: string;
	google_id: string;
	api_token: string;
	subscription?: { endDate: string; isActive: boolean };
	profile?: {
		creo_id: string;
		profile_picture_url: string;
		wallet_id: string;
		google_id: string;
		google_email: string;
		fb_id: string;
		fb_email: string;
		username: string;
		email: string;
		hash_id: string;
		account_type: string;
		last_login_at: string;
		last_login_app: string;
		login_key: string;
		login_key_expired_at: string;
		is_account_completed: boolean;
		bio: string;
		level: number;
		current_experience: number;
		next_level_experience: number;
		nft_owned: number;
		volume_trade: number;
		booster_level: string;
		badges: {
			id: number;
			name: string;
			image_url: string;
			description: string;
		}[];
		is_premium_subscribed: boolean;
		premium_subscription_until: string;
	};
}

const useAuthReducer = () => {
	const dispatch = useAppDispatch();
	const { user: userData } = useAppSelector(selectAuth);

	const user: IUser = userData;

	const setUser = (data: IUser) => {
		dispatch(
			setAuthUserData({
				user: data,
			}),
		);
	};

	const clearUser = () => {
		dispatch(
			setAuthUserData({
				user: null,
			}),
		);
	};

	const clearUserProfile = () => {
		let newUser = { ...user };
		delete newUser.profile;

		dispatch(
			setAuthUserData({
				user: newUser,
			}),
		);
	};

	return { user, setUser, clearUser, clearUserProfile };
};

export default useAuthReducer;

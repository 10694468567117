import { useQuery } from 'react-query';
import queryString from 'query-string';

import { removeEmptyParams } from 'src/utils/func/format-query';

import { useAPICaller } from './useAPICaller';

export const queryKeyBannerList = 'queryKeyBannerList';

export const useGetBannerList = (params?: any) => {
	const { fetchAPI } = useAPICaller();

	const paramsStr = queryString.stringify(removeEmptyParams(params));

	return useQuery(
		[queryKeyBannerList, params ?? {}],
		() => {
			return fetchAPI({
				endpoint: `/home/banners${params ? '?' + paramsStr : ''}`,
			});
		},
		{ keepPreviousData: true, refetchOnWindowFocus: false },
	);
};

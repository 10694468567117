import { useState } from 'react';

import CardConnectEmail from '../CardAuth/CardConnectEmail';
import CardCreateAccount from '../CardAuth/CardCreateAccount';
import CardForgotPassword from '../CardAuth/CardForgotPassword';
import CardLogin from '../CardAuth/CardLogin';
import CardSignUp from '../CardAuth/CardSignUp';
import ModalC, { ModalCProps } from '../Modal';

import classes from './index.module.less';

interface Props extends ModalCProps {
	type?: 'signup' | 'create-account' | 'login' | 'forgot-password' | 'connect-email';
	onClose?: (e?: any) => void;
}

const ModalAuth = ({ open, type: pType = 'signup', ...props }: Props) => {
	const [type, setType] = useState<Props['type']>(pType);
	const [isLoading, setIsLoading] = useState<boolean>(false);

	const onClose = () => {
		setType('signup');
		props.onClose?.();
	};

	return (
		<ModalC
			className={classes.modal}
			open={open}
			onClose={type === 'connect-email' ? props.onClose : onClose}
			centered
			showCloseIcon={false}
			closeOnOverlayClick={isLoading || type === 'create-account' ? false : true}
		>
			{type === 'signup' && (
				<CardSignUp
					onCreateAccountClick={() => setType('create-account')}
					onLoginClick={() => setType('login')}
					onSuccess={onClose}
					updateLoadingState={setIsLoading}
				/>
			)}
			{type === 'create-account' && (
				<CardCreateAccount
					onClose={() => setType('signup')}
					onSuccess={onClose}
					updateLoadingState={setIsLoading}
				/>
			)}
			{type === 'login' && (
				<CardLogin
					onForgotPasswordClick={() => setType('forgot-password')}
					onSuccess={onClose}
					updateLoadingState={setIsLoading}
				/>
			)}
			{type === 'forgot-password' && (
				<CardForgotPassword onSuccess={onClose} updateLoadingState={setIsLoading} />
			)}
			{type === 'connect-email' && (
				<CardConnectEmail
					onClose={props.onClose}
					onSuccess={props.onClose}
					updateLoadingState={setIsLoading}
				/>
			)}
		</ModalC>
	);
};

export default ModalAuth;

import { useEffect, useRef, useState } from 'react';
import CardAuth from '.';
import Col from 'antd/lib/col';
import Row from 'antd/lib/row';

import { useLoginEmail } from 'src/hooks';
import useAuthReducer from 'src/hooks/reducers/useAuthReducer';
import { notify } from 'src/utils/notify';

import Button from '../Button';
import Input from '../Input';

import classes from './index.module.less';

interface Props {
	onForgotPasswordClick?: () => void;
	onSuccess?: () => void;
	updateLoadingState?: (val: boolean) => void;
}

const CardLogin = ({ onForgotPasswordClick, onSuccess, updateLoadingState }: Props) => {
	const { setUser } = useAuthReducer();

	const { mutate: loginEmail, isLoading: isLoadingLoginEmail } = useLoginEmail();

	const [formData, setFormData] = useState<any>(null);
	const formRef = useRef(null);

	const isButtonLoginDisabled =
		formData == null ||
		Object.values(formData ?? [])?.find((val: any) => val.length < 3) !== undefined;

	const handleFormChange = () => {
		const newFormData = new FormData(formRef.current as any);
		const formProps = Object.fromEntries(newFormData);

		setFormData(formProps);
	};

	const handleLoginEmailClick = (e: any) => {
		e.preventDefault();

		loginEmail(
			{
				email: formData.email,
				password: formData.password,
			},
			{
				onSuccess: (res: any) => {
					if (res.status === 200 && res?.data?.data) {
						setUser(res.data.data);
						notify('Successfully logged in');
						onSuccess?.();
					} else {
						notify(res?.data?.message ?? 'Failed to login', 'error');
					}
				},
			},
		);
	};

	useEffect(() => {
		updateLoadingState?.(isLoadingLoginEmail);
	}, [isLoadingLoginEmail]);

	return (
		<CardAuth>
			<h1 className={classes.title}>Login to Creo Play</h1>

			<form
				ref={formRef}
				className={classes.form}
				onChange={handleFormChange}
				onSubmit={handleLoginEmailClick}
			>
				<Row gutter={[30, 12]}>
					<Col span={24}>
						<Input type="email" name="email" placeholder="Email" />
					</Col>
					<Col span={24}>
						<Input type="password" name="password" placeholder="Password" />
					</Col>
					<Col span={24}>
						<Button
							className="w-100"
							type="submit"
							loading={isLoadingLoginEmail}
							disabled={isButtonLoginDisabled}
						>
							Log in
						</Button>
					</Col>
				</Row>
			</form>
			<a className={classes['forgot-password']} onClick={() => onForgotPasswordClick?.()}>
				Forgot password?
			</a>
		</CardAuth>
	);
};

export default CardLogin;

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ethers } from 'ethers';

export type Web3State = {
	provider: any;
	web3Provider: ethers.providers.Web3Provider | null | undefined;
	address: string | null | undefined;
	network: ethers.providers.Network | null | undefined;
	creoBalance: number | null | undefined;
	tokenBalance: number | null | undefined;
	nftBalance: any | null | undefined;
	connect: (() => Promise<void>) | null;
	disconnect: (() => Promise<void>) | null;
	getContract: ((abiString: string, address: string) => Promise<any>) | null;
};

export type Web3Action = {
	['SET_WEB3_PROVIDER']: {
		provider?: Web3State['provider'];
		web3Provider?: Web3State['web3Provider'];
		address?: Web3State['address'];
		network?: Web3State['network'];
	};
	['SET_ADDRESS']: {
		address?: Web3State['address'];
	};
	['SET_NETWORK']: {
		network?: Web3State['network'];
	};
	['SET_BALANCE']: {
		creoBalance?: Web3State['creoBalance'];
		tokenBalance?: Web3State['tokenBalance'];
	};
	['SET_NFT']: {
		nftBalance?: Web3State['nftBalance'];
	};
	['RESET_WEB3_PROVIDER']: {};
};

export const web3InitialState: Web3State = {
	provider: null,
	web3Provider: null,
	address: null,
	network: null,
	creoBalance: null,
	tokenBalance: null,
	nftBalance: null,
	connect: null,
	disconnect: null,
	getContract: null,
};

export const web3Slice = createSlice({
	name: 'web3slice',
	initialState: web3InitialState,
	reducers: {
		setWeb3Provider(state: any, { payload }: PayloadAction<Web3Action['SET_WEB3_PROVIDER']>) {
			Object.assign(state, payload);
		},
		setWeb3Address(state: any, { payload }: PayloadAction<Web3Action['SET_ADDRESS']>) {
			Object.assign(state, payload);
		},
		setWeb3Network(state: any, { payload }: PayloadAction<Web3Action['SET_NETWORK']>) {
			Object.assign(state, payload);
		},
		setWeb3Balance(state: any, { payload }: PayloadAction<Web3Action['SET_BALANCE']>) {
			Object.assign(state, payload);
		},
		setWeb3Nft(state: any, { payload }: PayloadAction<Web3Action['SET_NFT']>) {
			Object.assign(state, payload);
		},
		resetWeb3Provider(state: any) {
			Object.assign(state, web3InitialState);
		},
	},
});

// Action creators are generated for each case reducer function
export const {
	setWeb3Provider,
	setWeb3Address,
	setWeb3Network,
	setWeb3Balance,
	setWeb3Nft,
	resetWeb3Provider,
} = web3Slice.actions;

export default web3Slice;

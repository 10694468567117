import { useWeb3Context } from 'src/context/web3';
import web3config from 'src/utils/web3config';

const useWeb3Helper = () => {
	const { address: userWalletAddress, getContract }: any = useWeb3Context();

	const web3Approval = async (targetAddress: string, priceItem: any) => {
		try {
			const { creoAbi, creoAddress } = web3config;
			const creoContract = await getContract(creoAbi, creoAddress);

			const allowance = await creoContract.methods
				.allowance(userWalletAddress, targetAddress)
				.call();

			if (BigInt(allowance) < BigInt(priceItem)) {
				// approval check
				const approval = await creoContract.methods
					.approve(targetAddress, priceItem)
					.send({ from: userWalletAddress });

				if (!approval) {
					throw new Error('User does not approve');
				}
			}

			return true;
		} catch (error) {
			throw error;
		}
	};

	return {
		web3Approval,
	};
};

export default useWeb3Helper;

/* eslint-disable no-use-before-define */
/* eslint-disable no-param-reassign */
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { nextReduxCookieMiddleware, wrapMakeStore } from 'next-redux-cookie-wrapper';
import { createWrapper, HYDRATE } from 'next-redux-wrapper';
import { AnyAction, combineReducers, configureStore, ThunkAction } from '@reduxjs/toolkit';

import authSlice from './slices/authSlice';
import web3Slice from './slices/web3Slice';

export const selectAuth = (state: AppState) => state[authSlice.name];
export const selectWeb3 = (state: AppState) => state[web3Slice.name];

const combinedReducers = combineReducers({
	[authSlice.name]: authSlice.reducer,
	[web3Slice.name]: web3Slice.reducer,
});

const rootReducer = (state: any, action: any) => {
	if (action.type === HYDRATE) {
		const nextState = {
			...state,
			...action.payload,
		};
		return nextState;
	}

	return combinedReducers(state, action);
};

const makeStore = wrapMakeStore(() =>
	configureStore({
		reducer: rootReducer,
		middleware: (getDefaultMiddleware: any) =>
			getDefaultMiddleware({
				serializableCheck: {
					ignoredActions: [
						'web3slice/setWeb3Provider',
						'web3slice/setWeb3Address',
						'web3slice/setWeb3Network',
						'web3slice/setWeb3Balance',
						'web3slice/setWeb3Nft',
						'web3slice/resetWeb3Provider',
					],
					ignoredPaths: ['web3slice.provider', 'web3slice.web3Provider', 'web3slice.network'],
				},
			}).prepend(
				nextReduxCookieMiddleware({
					subtrees: [
						{
							subtree: 'authSlice.user',
							cookieName: 'creo.persist',
						},
					],
				}),
			),
	}),
);

export type AppStore = ReturnType<typeof makeStore>;
export type AppState = ReturnType<AppStore['getState']>;
export type AppDispatch = AppStore['dispatch'];
export type AppThunkAction<ReturnType = Promise<void>> = ThunkAction<
	ReturnType,
	AppState,
	unknown,
	AnyAction
>;

export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<AppState> = useSelector;

export const wrapper = createWrapper<AppStore>(makeStore, { debug: true });

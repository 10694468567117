import { useMutation, useQuery } from 'react-query';
import queryString from 'query-string';

import { removeEmptyParams } from 'src/utils/func/format-query';
import { notify } from 'src/utils/notify';

import useAPICaller from './useAPICaller';

export const useGetShowcaseNft = (enabled = true) => {
	const { fetchAPI } = useAPICaller();

	return useQuery(
		['queryKeyGetShowcaseNft'],
		() =>
			fetchAPI({
				endpoint: '/profile/get-showcase',
			}),
		{ keepPreviousData: true, refetchOnWindowFocus: false, enabled: enabled },
	);
};

export const useUpdateShowcaseNFT = () => {
	const { fetchAPI } = useAPICaller();

	return useMutation(
		(ids: number[]) =>
			fetchAPI({
				endpoint: '/profile/modify-showcase',
				method: 'PUT',
				data: { showcase: ids },
			}),
		{
			onSuccess: () => {},
			onError: (err: any) => {
				notify(err?.message, 'error');
			},
		},
	);
};

export const useGetUserActivity = (params: any, enabled = true) => {
	const { fetchAPI } = useAPICaller();

	const paramsStr = queryString.stringify(removeEmptyParams(params));

	return useQuery(
		['queryKeyGetUserActivity'],
		() =>
			fetchAPI({
				endpoint: `/profile/get-user-activities${params ? '?' + paramsStr : ''}`,
			}),
		{ keepPreviousData: true, refetchOnWindowFocus: false, enabled: enabled },
	);
};

export const useGetDeals = (params: any, enabled = true) => {
	const { fetchAPI } = useAPICaller();

	const paramsStr = queryString.stringify(removeEmptyParams(params));

	return useQuery(
		['queryKeyGetDeals', params],
		() =>
			fetchAPI({
				endpoint: `/profile/get-deals${params ? '?' + paramsStr : ''}`,
			}),
		{ keepPreviousData: true, refetchOnWindowFocus: false, enabled: enabled },
	);
};

import Button, { ButtonProps } from 'antd/lib/button';
import classNames from 'classnames';

import classes from './index.module.less';

interface Props extends ButtonProps {
	className?: string;
	type?: any;
	disabled?: boolean;
	children?: any;
	loading?: boolean;
}

const ButtonC = ({
	className = '',
	type = 'button',
	disabled = false,
	children = '',
	loading = false,
	icon,
	iconPosition,
	...props
}: Props) => {
	return (
		<Button
			className={classNames(classes.button, { [classes.loading]: loading }, className)}
			htmlType={type}
			disabled={disabled}
			loading={loading}
			icon={icon}
			iconPosition={iconPosition}
			{...props}
		>
			{children}
		</Button>
	);
};

export const ButtonPrimary = (props: Props) => {
	return <ButtonC {...props} className={classNames(props.className, classes['button-primary'])} />;
};

export const ButtonBorder = (props: Props) => {
	return <ButtonC {...props} className={classNames(props.className, classes['button-border'])} />;
};

export const ButtonText = (props: Props) => {
	return <ButtonC {...props} className={classNames(props.className, classes['button-text'])} />;
};

export default ButtonC;
